<template>
    <div class="app__footer">
        <!-- start:: footer section -->
        <footer>
            <div class="container position-relative">
                <div class="row g-5">
                    <div class="col-lg-4 .col-md-4 .col-12">
                        <router-link to="/" class="navbar-brand" href="#">
                            <img
                                src="./../../assets/images/logo.png"
                                alt="..."
                            />
                        </router-link>
                        <p class="lh-lg">
                            {{ description }}
                        </p>
                        <div class="social-links">
                            <a
                                :href="facebook"
                                class="text-decoration-none pe-3"
                            >
                                <v-icon class="social__icon facebook"
                                    >mdi-facebook</v-icon
                                >
                            </a>
                            <a
                                :href="twitter"
                                class="text-decoration-none pe-3"
                            >
                                <v-icon class="social__icon twitter"
                                    >mdi-twitter</v-icon
                                >
                            </a>
                            <a
                                :href="whatsapp"
                                class="text-decoration-none pe-3"
                            >
                                <v-icon class="social__icon whatsapp"
                                    >mdi-whatsapp</v-icon
                                >
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-4 .col-md-4 .col-12">
                        <h1>استكشف</h1>
                        <div class="row links g-3">
                            <div class="col-lg-6 col-md-6 col-12">
                                <RouterLink
                                    :to="{ name: 'home' }"
                                    class="text-decoration-none d-flex align-items-center gap-2"
                                >
                                    <v-icon class="chevron-right"
                                        >mdi-chevron-left</v-icon
                                    >
                                    <span>الرئيسيه</span>
                                </RouterLink>
                                <RouterLink
                                    :to="{ name: 'contacts' }"
                                    class="text-decoration-none d-flex align-items-center gap-2 pt-3"
                                >
                                    <v-icon class="chevron-right"
                                        >mdi-chevron-left</v-icon
                                    >
                                    <span>تواصل معنا</span>
                                </RouterLink>
                                <RouterLink
                                    :to="{ name: 'blogs' }"
                                    class="text-decoration-none d-flex align-items-center gap-2 pt-3"
                                >
                                    <v-icon class="chevron-right"
                                        >mdi-chevron-left</v-icon
                                    >
                                    <span>المدونه</span>
                                </RouterLink>
                                <!-- <RouterLink
                  :to="{ name: 'home' }"
                  class="text-decoration-none d-flex align-items-center gap-2 pt-3"
                >
                  <v-icon class="chevron-right">mdi-chevron-left</v-icon>
                  <span></span>
                </RouterLink> -->
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <span
                                    class="text-decoration-none d-flex align-items-center gap-2"
                                    @click="
                                        $router.push({
                                            name: 'category',
                                            params: {
                                                categoryname: men.name,
                                                id: men.id,
                                            },
                                        })
                                    "
                                >
                                    <v-icon class="chevron-right"
                                        >mdi-chevron-left</v-icon
                                    >
                                    <span>منتجات رجال</span>
                                </span>
                                <span
                                    @click="
                                        $router.push({
                                            name: 'category',
                                            params: {
                                                categoryname: women.name,
                                                id: women.id,
                                            },
                                        })
                                    "
                                    class="text-decoration-none d-flex align-items-center gap-2 pt-3"
                                >
                                    <v-icon class="chevron-right"
                                        >mdi-chevron-left</v-icon
                                    >
                                    <span>منتجات سيدات</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 .col-md-4 .col-12">
                        <h1>تواصل معنا</h1>
                        <div class="contacts">
                            <a
                                :href="`mailto:${emails}`"
                                class="text-decoration-none d-flex align-items-center gap-2"
                            >
                                <v-icon>mdi-email-check-outline</v-icon>
                                <span>{{ email }}</span>
                            </a>
                            <a
                                :href="`tel:${phone}`"
                                class="text-decoration-none d-flex align-items-center gap-2 pt-3"
                            >
                                <v-icon>mdi-phone</v-icon>
                                <span style="direction: ltr">{{ phone }}</span>
                            </a>
                            <RouterLink
                                :to="{ name: 'home' }"
                                class="text-decoration-none d-flex align-items-center gap-2 pt-3"
                            >
                                <v-icon>mdi-map-marker-radius-outline</v-icon>
                                <span>{{ address }}</span>
                            </RouterLink>
                            <!-- <RouterLink
                :to="{ name: 'home' }"
                class="text-decoration-none d-flex align-items-center gap-2 pt-3"
              >
                <v-icon>mdi-clock-outline</v-icon>
                <span> Mon - Sat(8am - 5pm)</span>
              </RouterLink> -->
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end:: footer section -->
    </div>
</template>

<script>
import { useCategoriesStore } from "@/store/HomeStore/catigories.js";
import { mapState } from "pinia";
export default {
    name: "AppFooter",
    props: {
        phone: {
            type: String,
            default: "phone",
        },
        address: {
            type: String,
            default: "phone",
        },
        facebook: {
            type: String,
            default: "phone",
        },
        twitter: {
            type: String,
            default: "phone",
        },
        instegram: {
            type: String,
            default: "instegram",
        },
        email: {
            type: String,
            default: "email",
        },
        description: {
            type: String,
            default: "description",
        },
    },

    computed: {
        ...mapState(useCategoriesStore, ["catigories", "men", "women"]),
    },
};
</script>

<style lang="scss" scoped>
footer {
    padding-top: 3cap;
    padding-bottom: 3rem;
    background-color: #f5f5f5;
    @media (max-width: 992px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    background-repeat: no-repeat;
    background-size: cover;
    .navbar-brand {
        img {
            max-width: 13rem;
        }
    }
    p {
        padding-top: 40px;
        color: black;
        margin-bottom: 0;
    }
    .btn {
        margin-top: 40px;
        background-color: black;
        color: #fff;
        &:hover {
            background-color: black;
            color: #fff;
            svg {
                transform: translateX(5px);
                transition: all 0.3s ease;
            }
        }
    }
    .social-links {
        padding-top: 40px;
        .social__icon {
            padding: 10px;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            color: #000;
            transition: all 0.3s ease;
            &:hover {
                color: #ccc;
            }
        }
        a {
            color: #fff;
        }
    }
    h1 {
        color: #000;
        font-weight: bold;
        margin: 0;
    }
    .col-lg-4 {
        .row {
            padding-top: 40px;
            a {
                transition: all 0.5s ease;
                &:hover {
                    padding-left: 10px;
                }
            }
            .chevron-right {
                color: #000;
            }
            span {
                color: black;
                font-size: 17px;
            }
        }
    }
    .contacts {
        padding-top: 40px;
        a {
            color: black;
            svg {
                color: #fff;
            }
        }
    }
}
</style>
