import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import colors from "vuetify/util/colors";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "primevue/resources/themes/aura-light-green/theme.css";

//Emitter config
import mitt from "mitt";
const Emitter = mitt();

// vuetify & mdi configration
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        default: "dark",
        themes: {
            light: {
                colors: {
                    primary: "#000",
                    secondary: colors.red.lighten4,
                },
            },
        },
    },
});

const app = createApp(App);
app.use(router);
app.use(PrimeVue);
app.use(createPinia());
app.use(vuetify);
app.provide("Emitter", Emitter);
app.mount("#app");
