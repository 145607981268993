<template>
    <div class="app__layout">
        <v-container>
            <v-layout>
                <NavDrawer />
                <AppBar />
                <SecondAppbar />
            </v-layout>
        </v-container>
        <v-layout>
            <v-main class="main-section">
                <slot></slot>
            </v-main>
        </v-layout>
        <AppFooter
            :phone="phone"
            :address="address"
            :facebook="facebook"
            :twitter="twitter"
            :whatsapp="whatsapp"
            :email="email"
            :description="description"
        />
    </div>
</template>

<script>
import NavDrawer from "./NavDrawer.vue";
import AppBar from "./AppBar.vue";
import AppFooter from "./AppFooter.vue";
import SecondAppbar from "./SecondAppbar.vue";
import { useFooterStore } from "@/store/footer.js";
import { mapState, mapActions } from "pinia";
export default {
    data: () => ({
        drawer: false,
    }),
    components: {
        NavDrawer,
        AppBar,
        AppFooter,
        SecondAppbar,
    },
    computed: {
        ...mapState(useFooterStore, [
            "phone",
            "address",
            "facebook",
            "twitter",
            "whatsapp",
            "email",
            "description",
        ]),
    },

    methods: {
        ...mapActions(useFooterStore, ["getFooterData"]),
    },

    async mounted() {
        await this.getFooterData();
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    padding: 0;
}
</style>
