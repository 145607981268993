<template>
    <v-app-bar class="pt-2 pb-2 app__header" absolute>
        <v-container>
            <div
                class="app__bar d-flex justify-space-between align-center w-100"
            >
                <div class="end__icons d-flex align-center ga-0">
                    <v-btn
                        rounded
                        @click="OpenDrawer"
                        class="d-lg-none d-md-block d-block"
                    >
                        <v-icon size="x-large">mdi-menu</v-icon>
                    </v-btn>
                    <!-- start:: search -->
                    <div
                        class="relative d-lg-block d-md-none d-none"
                        ref="searchContainer"
                    >
                        <div
                            class="bg-transparent mx-5 rounded-lg hidden items-center lg:flex border-gray-300 border py-2 px-1 w-96"
                        >
                            <input
                                style="direction: rtl"
                                v-model="searchInput"
                                @input="handleSearch"
                                class="w-full bg-transparent font-semibold text-sm px-4"
                                type="text"
                                placeholder="ابحث فى المنتجات "
                            />
                            <button
                                @click.prevent="handleSearch"
                                type="button"
                                title="Search"
                            >
                                <i class="fas fa-search text-gray-400 p-2"></i>
                            </button>
                        </div>

                        <!-- Display search results -->
                        <div
                            v-if="searchResults.length > 0"
                            class="lg:absolute left-4 w-96 bg-white rounded-md p-2 shadow-lg z-50 small__search"
                        >
                            <ul class="none list-unstyled">
                                <li
                                    v-for="result in searchResults"
                                    :key="result.id"
                                    @click="navigateToProduct"
                                    class="hover:bg-gray-100 p-1 rounded-md my-1 cursor-pointer transition duration-100"
                                >
                                    <router-link
                                        class="text-decoration-none text-black"
                                        :to="{
                                            name: 'singleproduct',
                                            params: {
                                                productname: result.name.slice(
                                                    0,
                                                    5
                                                ),
                                                id: result.id,
                                            },
                                        }"
                                    >
                                        {{ truncateName(result.name) }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- end search -->
                </div>

                <div
                    class="app__links d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none"
                >
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                class="text-capitalize header__link"
                                @click="$router.push({ name: 'blogs' })"
                                v-bind="props"
                                elavation="0"
                            >
                                المدونه
                            </v-btn>
                        </template>
                    </v-menu>

                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                class="text-capitalize header__link"
                                @click="$router.push({ name: 'contacts' })"
                                v-bind="props"
                                elavation="0"
                            >
                                تواصل معنا
                            </v-btn>
                        </template>
                    </v-menu>

                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                class="text-capitalize header__link"
                                v-bind="props"
                                elavation="0"
                            >
                                <v-icon start icon="mdi-chevron-down"></v-icon>
                                <span>الأقسام</span>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <router-link
                                    :to="{
                                        name: 'category',
                                        params: {
                                            categoryname: catigories[0].name,
                                            id: catigories[0].id,
                                        },
                                    }"
                                    class="text-decoration-none text-black"
                                >
                                    <v-list-item-title class="text-capitalize">
                                        الرجال
                                    </v-list-item-title>
                                </router-link>
                            </v-list-item>
                            <v-list-item>
                                <router-link
                                    :to="{
                                        name: 'category',
                                        params: {
                                            categoryname: catigories[0].name,
                                            id: catigories[1].id,
                                        },
                                    }"
                                    class="text-decoration-none text-black"
                                >
                                    <v-list-item-title class="text-capitalize">
                                        السيدات
                                    </v-list-item-title>
                                </router-link>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                @click="$router.push({ name: 'home' })"
                                class="text-capitalize header__link"
                                v-bind="props"
                                elavation="0"
                            >
                                الرئيسيه
                            </v-btn>
                        </template>
                    </v-menu>
                </div>

                <router-link to="/" class="brand__logo">
                    <img src="./../../assets/images/logo.png" alt="..." />
                </router-link>
            </div>
        </v-container>
    </v-app-bar>
</template>

<script>
import { headerStore } from "@/store/header";
import { useCategoriesStore } from "@/store/HomeStore/catigories.js";
import { mapState } from "pinia";
import axios from "axios";
import { url } from "@/config";
// import SearchBar from "@/components/glopal/SearchBar.vue";
export default {
    name: "AppBar",

    inject: ["Emitter"],

    data: () => ({
        searchInput: "",
        searchResults: [],
    }),

    computed: {
        ...mapState(headerStore, ["links"]),
        ...mapState(useCategoriesStore, ["catigories"]),
    },

    // components: {
    //     SearchBar,
    // },

    methods: {
        OpenDrawer() {
            this.Emitter.emit("OpenDrawer");
        },

        async handleSearch() {
            if (this.searchInput.trim() === "") {
                // If search query is empty, hide the search results modal
                this.searchResults = [];
                return;
            }
            await axios
                .get(`${url}/search-product/${this.searchInput}`)
                .then((res) => {
                    this.searchResults = res.data.data.slice(0, 5);
                });
        },

        truncateName(name) {
            const maxLength = 30;
            if (name.length > maxLength) {
                return name.slice(0, maxLength) + "...";
            } else {
                return name;
            }
        },

        navigateToProduct() {
            this.searchResults = [];
            this.searchInput = "";

            // this.$router.push(`/${slug_url}/${id}`);
        },
    },
};
</script>

<style lang="scss">
.brand__logo {
    cursor: pointer;
    img {
        max-width: 150px;
        max-height: 150px;
    }
}

.header__link {
    letter-spacing: 0 !important;
    font-size: 17px !important;
}

.v-toolbar__content {
    overflow: visible !important;
}

input {
    outline: 0;
}

.small__search {
    position: absolute;
    top: 59px;
    transform: translateX(11%);
    border-radius: 6px;
    border: 1px solid #ddd;
}
</style>
