<template>
    <div class="app__nav">
        <v-navigation-drawer
            v-model="drawer"
            temporary
            location="right"
            class="pa-5 text-center"
        >
            <div class="d-flex justify-space-between align-center">
                <v-icon @click="drawer = !drawer">mdi-window-close</v-icon>
                <h2 class="text-left text-h6 font-weight-bold mb-0">
                    تصفح الموقع
                </h2>
            </div>
            <ul class="mt-3 list-unstyled">
                <li
                    class="links mb-3 text-small-emphasis"
                    style="font-size: 15px"
                >
                    <router-link
                        class="single__link text-decoration-none"
                        :to="{ name: 'home' }"
                    >
                        الرئيسيه
                    </router-link>
                </li>
                <li
                    class="links mb-3 text-small-emphasis"
                    style="font-size: 15px"
                >
                    <div
                        class="single__link text-decoration-none"
                        @click="
                            $router.push({
                                name: 'category',
                                params: {
                                    categoryname: women.name,
                                    id: women.id,
                                },
                            })
                        "
                    >
                        منتجات السيدات
                    </div>
                </li>
                <li
                    class="links mb-3 text-small-emphasis"
                    style="font-size: 15px"
                >
                    <div
                        class="single__link text-decoration-none"
                        @click="
                            $router.push({
                                name: 'category',
                                params: {
                                    categoryname: men.name,
                                    id: men.id,
                                },
                            })
                        "
                    >
                        منتجات الرجال
                    </div>
                </li>
                <li
                    class="links mb-3 text-small-emphasis"
                    style="font-size: 15px"
                >
                    <router-link
                        class="single__link text-decoration-none"
                        :to="{ name: 'blogs', params: { id: 1 } }"
                    >
                        المدونه
                    </router-link>
                </li>
                <li
                    class="links mb-3 text-small-emphasis"
                    style="font-size: 15px"
                >
                    <router-link
                        class="single__link text-decoration-none"
                        :to="{ name: 'contacts' }"
                    >
                        تواصل معنا
                    </router-link>
                </li>
            </ul>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { headerStore } from "@/store/header";
import { mapState } from "pinia";
import { useCategoriesStore } from "@/store/HomeStore/catigories.js";
export default {
    inject: ["Emitter"],
    data: () => ({
        drawer: false,
    }),

    computed: {
        ...mapState(headerStore, ["links"]),
        ...mapState(useCategoriesStore, ["catigories", "men", "women"]),
    },

    mounted() {
        this.Emitter.on("OpenDrawer", () => {
            this.drawer = true;
        });
    },
};
</script>

<style lang="scss" scoped>
.links {
    .single__link {
        transition: all 0.3s ease;
        color: #000;
        cursor: pointer;
        &:hover {
            color: #a421a9;
            padding-left: 10px;
        }
    }
}
</style>
