import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
    },
    {
        path: "/:categoryname/:id",
        name: "category",
        component: () => import("@/views/CategoryProducts.vue"),
    },
    {
        path: "/blogs",
        name: "blogs",
        component: () => import("@/views/AllBlogs.vue"),
    },
    {
        path: "/blogs/:id",
        name: "singleblog",
        component: () => import("@/views/SingleBlog.vue"),
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/views/ContactUs.vue"),
    },
    {
        path: "/:productname/:id",
        name: "singleproduct",
        component: () => import("@/views/SingleProduct.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                el: to.hash,
                behavior: "smooth",
            };
        }
        return { top: 0 };
    },
});

export default router;
