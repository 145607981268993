<template>
    <v-app-bar style="top: 0">
        <v-container class="d-flex justify-space-between align-center">
            <div
                class="app__links d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none"
            >
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="primary"
                            class="text-capitalize header__link"
                            @click="
                                $router.push({
                                    name: 'blogs',
                                })
                            "
                            v-bind="props"
                        >
                            المدونه
                        </v-btn>
                    </template>
                </v-menu>

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="primary"
                            class="text-capitalize header__link"
                            @click="$router.push({ name: 'contacts' })"
                            v-bind="props"
                        >
                            تواصل معنا
                        </v-btn>
                    </template>
                </v-menu>

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="primary"
                            class="text-capitalize header__link"
                            v-bind="props"
                            elavation="0"
                        >
                            <v-icon start icon="mdi-chevron-down"></v-icon>
                            <span>الأقسام</span>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <router-link
                                :to="{
                                    name: 'category',
                                    params: {
                                        categoryname: catigories[0].name,
                                        id: catigories[0].id,
                                    },
                                }"
                                class="text-decoration-none text-black"
                            >
                                <v-list-item-title class="text-capitalize">
                                    الرجال
                                </v-list-item-title>
                            </router-link>
                        </v-list-item>
                        <v-list-item>
                            <router-link
                                :to="{
                                    name: 'category',
                                    params: {
                                        categoryname: catigories[0].name,
                                        id: catigories[1].id,
                                    },
                                }"
                                class="text-decoration-none text-black"
                            >
                                <v-list-item-title class="text-capitalize">
                                    السيدات
                                </v-list-item-title>
                            </router-link>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="primary"
                            class="text-capitalize header__link"
                            @click="$router.push({ name: 'home' })"
                            v-bind="props"
                        >
                            الرئيسيه
                        </v-btn>
                    </template>
                </v-menu>
            </div>

            <div class="brand__logo" @click="$router.push({ name: 'home' })">
                <img src="./../../assets/images/logo.jpg" alt="..." />
            </div>

            <div
                class="end__icons d-lg-none d-md-flex d-flex align-center ga-0"
            >
                <v-btn rounded @click="OpenDrawer">
                    <v-icon size="x-large">mdi-menu</v-icon>
                </v-btn>
            </div>
        </v-container>
    </v-app-bar>
</template>

<script>
import { headerStore } from "@/store/header";
import { mapState } from "pinia";
import { useCategoriesStore } from "@/store/HomeStore/catigories.js";

export default {
    name: "SecondAppbar",
    inject: ["Emitter"],

    computed: {
        ...mapState(headerStore, ["links"]),
        ...mapState(useCategoriesStore, ["catigories"]),
    },

    methods: {
        OpenDrawer() {
            this.Emitter.emit("OpenDrawer");
        },
    },
};
</script>

<style lang="scss" scoped>
.brand__logo {
    cursor: pointer;
    img {
        max-width: 150px;
        max-height: 150px;
    }
}

.header__link {
    letter-spacing: 0;
    font-size: 17px;
}
</style>
