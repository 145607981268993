import { defineStore } from "pinia";
import axios from "axios";
import { url } from "@/config";

export const useCategoriesStore = defineStore("useCategoriesStore", {
    state: () => ({
        catigories: [],
        men: [],
        women: [],
    }),

    actions: {
        async getCategories() {
            await axios.get(`${url}/all-ctegories`).then((res) => {
                this.catigories = res.data.data;
                this.men = res.data.data[0];
                this.women = res.data.data[1];
                console.log(this.catigories);
            });
        },
    },
});
