import { defineStore } from "pinia";
import axios from "axios";
import { url } from "@/config";

export const useFooterStore = defineStore("useFooterStore", {
    state: () => ({
        phone: "",
        address: "",
        facebook: "",
        twitter: "",
        whatsapp: "",
        email: "",
        description: "",
        bannerTittle: "",
        categoriesTitle: "",
        blogsTitle: "",
        productsTitle: "",
        feedbackTitle: "",
    }),

    actions: {
        async getFooterData() {
            await axios.get(`${url}/settings`).then((res) => {
                this.phone = res.data.data[0].phone;
                this.address = res.data.data[0].address;
                this.facebook = res.data.data[0].facebook_link;
                this.twitter = res.data.data[0].twitter_link;
                this.whatsapp = res.data.data[0].whatsapp_link;
                this.email = res.data.data[0].email;
                this.description = res.data.data[0].description;
                this.bannerTittle = res.data.data[0].banar_title;
                this.categoriesTitle = res.data.data[0].category_title;
                this.blogsTitle = res.data.data[0].blog_title;
                this.productsTitle = res.data.data[0].product_title;
                this.feedbackTitle = res.data.data[0].feed_back_title;
                console.log("categoriesTitle:", this.categoriesTitle);
            });
        },
    },
});
