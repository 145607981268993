<template>
    <app-layout>
        <router-view />
    </app-layout>
</template>

<script>
import AppLayout from "./components/glopal/AppLayout.vue";
import { useFooterStore } from "@/store/footer";
import { mapState, mapActions } from "pinia";
export default {
    name: "AppVue",
    components: {
        AppLayout,
    },

    computed: {
        ...mapState(useFooterStore, ["footer"]),
    },

    methods: {
        ...mapActions(useFooterStore, ["getFooterData"]),
    },
};
</script>

<style lang="scss">
body {
    max-width: 100%;
    overflow-x: hidden;
}
#app {
    font-family: tahoma;
    letter-spacing: 0;
    direction: rtl;
}

/* width */
body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: #a421a9;
    border-radius: 8px;
    border: 1px solid #fff;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: #a421a9;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
